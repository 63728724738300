import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.showPopup();
  }

  showPopup() {
    document.getElementById("popup").style.display = "block";
    document.getElementById("popup-overlay").style.display = "block";
  }

  closePopup() {
    document.getElementById("popup").style.display = "none";
    document.getElementById("popup-overlay").style.display = "none";
  }
}
