// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"
import Cleave from "cleave.js"
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
require('cleave.js/dist/addons/cleave-phone.br')

export default class extends Controller {

  static targets = ["formErrors", "messages", "nav", "menuBars", "closeIconMenu", "cookies"]

  initialize() {
    this.flatpickrConfig = {
      altInput: true,
      altFormat: "j/m/Y",
      locale: Portuguese
    }
  }

  connect() {
    // document.addEventListener("contextmenu", function(e){
    //     e.preventDefault()
    // }, false)

    this.resize()

    // Verify Cookie
    if (document.cookie.indexOf("accepted_cookies=") < 0) {
      this.cookiesTarget.classList.remove("is-display-none")
    }

    // Verify User Timezone
    if (document.cookie.indexOf("user_timezone=") < 0 || document.cookie.indexOf("GMT+") >= 0) {
      let cookieDate = new Date;
      let timeZone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)
      cookieDate.setFullYear(cookieDate.getFullYear() + 5);
      if (timeZone)
        document.cookie = `user_timezone=${timeZone}; max-expires=${cookieDate.toUTCString()};`
    }

    // Initialize Choices
    document.querySelectorAll("input[type='text'].choices").forEach(
      function(currentValue, currentIndex, listObj) {
        const choices = new Choices(currentValue, {
          removeItems: true,
          removeItemButton: true,
          editItems: true,
          addItems: true,
          itemSelectText: "Pressione enter para adicionar"
        })
      }
    )
    this.initializeSelects(document.querySelectorAll("input[type='text'].choices"))

    // MASKS

    // Mask for numeric fields
    document.querySelectorAll(".input-number").forEach(
      function(currentValue, currentIndex, listObj) {
        new Cleave(currentValue, {
          blocks: [10],
          numericOnly: true
        })
      }
    )

    // Mask for document number fields
    document.querySelectorAll(".input-document-number").forEach(
      function(currentValue, currentIndex, listObj) {
        new Cleave(currentValue, {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true
        })
      }
    )

    // Mask for date fields
    document.querySelectorAll(".input-date").forEach(
      function(currentValue, currentIndex, listObj) {
        new Cleave(currentValue, {
          delimiters: ["/"],
          blocks: [2, 2],
          numericOnly: true
        })
      }
    )

    // Mask for card number fields
    document.querySelectorAll(".input-card-number").forEach(
      function(currentValue, currentIndex, listObj) {
        new Cleave(currentValue, {
          delimiters: ["  ", "  ", "  "],
          blocks: [4, 4, 4, 4],
          numericOnly: true
        })
      }
    )

    // Mask for zipcode fields
    document.querySelectorAll(".input-zipcode").forEach(
      function(currentValue, currentIndex, listObj) {
        new Cleave(currentValue, {
          delimiters: ["-"],
          blocks: [5, 3],
          numericOnly: true
        })
      }
    )

    // Mask for br phone fields
    document.querySelectorAll(".input-phone").forEach(
      function(currentValue, currentIndex, listObj) {
        new Cleave(currentValue, {
          phone: true,
          phoneRegionCode: 'BR'
        })
      }
    )

    if (window.location.hash) {
      let element_to_scroll_to = document.querySelector(window.location.hash);
      element_to_scroll_to.scrollIntoView();
    }

    if (document.querySelector(".date-select-input")) {
      flatpickr(".date-select-input", this.flatpickrConfig)
    }
  }

  // Function to initialize selects with padding preserved
  initializeSelects(events) {
    events.forEach((item, i) => {
      if (item.value == "") {
        item.parentElement.classList.remove("has-options")
      } else {
        item.parentElement.classList.add("has-options")
      }
    })
  }

  // Function to preserve padding layout with item is selected
  changeSelect(event) {
    if (event.target.value == "") {
      event.target.parentElement.classList.remove("has-options")
    } else {
      event.target.parentElement.classList.add("has-options")
    }
  }

  // Function for prevent browser events
  preventDefault(event) {
    event.preventDefault()
  }

  // Function for change page informations
  recoverPassword() {
    document.getElementById("form").classList.remove("is-display-block")
    document.getElementById("passwordInstructions").classList.add("is-display-block")
  }

  // Error form
  onPostError(event) {
    console.log(event)
    const [data, status, xhr] = event.detail
    console.log(xhr)
    let formErrors = this.formErrorsTarget
    if (event.path && event.path[0].id == "articles-form") {
      document.getElementById('articles-form').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    formErrors.innerText = ""

    for (var i = 0; i < JSON.parse(xhr.response).length; i++) {
      var error = JSON.parse(xhr.response)[i]
      var li = document.createElement("li")
      li.classList.add("error")
      li.appendChild(document.createTextNode(error))
      formErrors.appendChild(li)
    }
  }

  // Success contact form
  onContactPostSuccess(event) {
    this.formErrorsTarget.innerText = ""
    this.messagesTarget.innerText = ""
    let [data, status, xhr] = event.detail
    var p = document.createElement("p")
    p.classList.add("success")
    p.appendChild(document.createTextNode("Sua mensagem foi enviada com sucesso"))
    this.messagesTarget.appendChild(p)
  }

  // Success form
  onPostSuccess(event) {
    this.formErrorsTarget.innerText = ""
    let [data, status, xhr] = event.detail
  }

  // Show and hide mobile menu
  resize() {
    let headers = document.querySelectorAll("header.event, header.into, header.user, header.out")

    headers.forEach(
      function(currentValue, currentIndex, listObj) {
        if (document.body.clientWidth > 992) {
          currentValue.querySelector("[data-target='application.menuBars']").classList.add("is-display-none")
          currentValue.querySelector("[data-target='application.closeIconMenu']").classList.add("is-display-none")
          currentValue.querySelector("[data-target='application.nav']").classList.remove("mobile")
          currentValue.querySelector("[data-target='application.nav']").classList.remove("is-display-none")
        } else {
          currentValue.querySelector("[data-target='application.menuBars']").classList.remove("is-display-none")
          currentValue.querySelector("[data-target='application.closeIconMenu']").classList.remove("is-display-none")
          currentValue.querySelector("[data-target='application.nav']").classList.add("mobile")
          currentValue.querySelector("[data-target='application.nav']").classList.add("is-display-none")
        }
      }
    )
  }

  // Function to open menu
  openMenu(event) {
    event.target.parentElement.querySelector("nav.mobile").classList.remove("is-display-none")
  }

  // Function to close menu
  closeMenu(event) {
    event.target.parentElement.parentElement.querySelector("nav.mobile").classList.add("is-display-none")
  }

  // Open modal
  openModal(event) {
    event.target.closest("div").querySelector(".modal").style.display = "block"
  }

  // Close modal in click close icon
  closeModal() {
    document.querySelector('.modal[style*="display: block"]').style.display = "none"
  }

  // Close modal in click out modal area
  closeModalWithClickOut(event) {
    var modal = document.querySelector('.modal[style*="display: block"]')
    if (event.target == modal) {
      modal.style.display = "none"
    }
  }

  // Function to call an click input
  triggeredInput(event) {
    event.target.parentNode.parentNode.querySelector("input[type='file']").click()
  }

  // Function to change image if button is clicked
  changeImage(event) {
    const file = event.target.files[0]
    const img = event.target.parentNode.querySelector(".picture")
    const reader = new FileReader()
    reader.readAsDataURL(file, "UTF-8")
    reader.onload = function(e) {
      img.src = e.target.result
      const icon = event.target.parentNode.querySelector(".icon-image")
      if (icon) {
        icon.classList.add("is-display-none")
      }
      img.classList.remove("is-display-none")
    }
    reader.onerror = function(e) {

    }
  }

  // Accept cookies
  acceptCookies() {
    document.cookie = "accepted_cookies=yes; max-age=315360000; path=/"
    this.cookiesTarget.classList.add("is-display-none")
  }

  refreshPage() {
    location.reload()
  }

  temporarilyDisableClick(e) {
    let button = e.currentTarget
    button.classList.add("is-display-none")
    window.location.href = button.href
    setTimeout(function() {
      button.classList.remove("is-display-none")
    }, 1000)
  }
}