// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {

  static targets = [ "form", "formErrors", "message", "user", "isSpeaker", "eventEdition", "event", "startAt", "endAt", "issueDate", "digitalSubscription", "code" ]

  connect() {
  }

  // Error form
  onPostError(event) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.formErrorsTarget.innerText = ""
    const [data, status, xhr] = event.detail
    for (var i = 0; i < JSON.parse(xhr.response).length; i++) {
      var error = JSON.parse(xhr.response)[i]
      var li = document.createElement("li")
      li.appendChild(document.createTextNode(error))
      this.formErrorsTarget.appendChild(li)
    }
  }

  // Success contact form
  onPostSuccess(event) {
    let [data, status, xhr] = event.detail
    let responseJson = JSON.parse(xhr.responseText)
    this.formErrorsTarget.innerText = ""
    this.formTarget.classList.add("is-display-none")
    this.messageTarget.classList.remove("is-display-none")
    this.userTarget.textContent = responseJson.user
    this.eventEditionTarget.textContent = responseJson.eventEdition
    this.eventTarget.textContent = responseJson.event
    // this.startAtTarget.textContent = responseJson.startAt
    // this.endAtTarget.textContent = responseJson.endAt
    this.issueDateTarget.textContent = responseJson.issueDate
    this.codeTarget.textContent = responseJson.code
    this.digitalSubscriptionTarget.classList.remove("is-display-none")
    if (responseJson.isSpeaker) {
      // this.isSpeakerTarget.classList.remove("is-display-none")
      // this.digitalSubscriptionTarget.classList.remove("is-display-none")
    }
  }
}