// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { Calendar } from '@fullcalendar/core';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import dayGridMonth from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import tippy from 'tippy.js';

export default class extends Controller {

  static targets = [ "formErrors", "messages", "newUserModal", "eventFormQuestionsModal", "eventFormQuestionsContent", "calendarStartDate", "articleEvaluationDetailsDiv" ]

  initialize() {
    self = this
    let element = document.querySelector(".event-edition-products-button")

    if (element) {
      setTimeout(function(){
        Rails.fire(element, "click")
      }, 100)
    }

    let toAddEvents = document.querySelectorAll("#event-edition-calendar-draggable > .fc-event")
    let alreadyAddedEvents = document.querySelectorAll("#event-edition-calendar-added .event-remove-button")
    let calendarEl = document.querySelector("#event-edition-calendar")
    if (calendarEl) {
      this.calendar = new Calendar(calendarEl, {
        plugins: [ dayGridMonth, timeGridPlugin ],
        initialView: 'timeGridWeek',
        initialDate: self.calendarStartDateTarget.value,
        locale: ptBrLocale,
        customButtons: {
          exportButton: {
            text: 'Exportar agenda',
            click: function(event) {
              self.startCalendarExport(event, calendarEl.dataset.eventId, calendarEl.dataset.eventEditionId)
            }
          }
        },
        datesSet: function(data) {
          toAddEvents.forEach(function(item){
            let element = item.querySelector(".event-buttons a")
            let elementRemain = item.closest(".fc-event").querySelector(".event-remain-registrations")

            if (new Date(element.dataset.eventStart) >= data.start && new Date(element.dataset.eventStart) <= data.end)
              item.classList.remove("is-display-none")
            else {
              item.classList.add("is-display-none")
              item.closest(".fc-event").classList.remove("fc-event-active")
            }

            self.calculateEventCalendarRegistrationsRemains(element.dataset.eventMaximum, element.dataset.eventRegistrations, elementRemain)
          })
        },
        headerToolbar: {
          left: 'exportButton',
          center: 'prev,title,next',          
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        eventDidMount: function (info) {
          if (!info.event._def.groupId.length) {
            let element = info.el.querySelector(".fc-event-title")
            let elementRemain = document.querySelector(`.event-buttons a[data-event-id='${info.event._def.publicId}']`).closest(".fc-event").querySelector(".event-remain-registrations")

            document.querySelector(`.event-buttons a[data-event-id='${info.event._def.publicId}']`).closest(".fc-event").classList.remove("fc-event-active")

            self.calculateEventCalendarRegistrationsRemains(info.event._def.extendedProps.maximum, info.event._def.extendedProps.registrations, elementRemain)
            tippy(element, {
              content: element.innerHTML,
              placement: 'top',
              appendTo: () => document.body
            });
          }
        }
      })
      alreadyAddedEvents.forEach(function(event){
        let dataSet = event.dataset
        let eventData = {id: dataSet.eventId, title: dataSet.eventTitle, start: dataSet.eventStart, end: dataSet.eventEnd, extendedProps: {eventId: dataSet.eventRefId, eventEditionId: dataSet.eventEditionId, eventEditionCalendarId: dataSet.eventEditionCalendarId}}
        let elementRemain = event.closest(".fc-event").querySelector(".event-remain-registrations")
        if (dataSet.eventMaximum)
          eventData["extendedProps"]["maximum"] = dataSet.eventMaximum
        if (dataSet.eventRegistrations)
          eventData["extendedProps"]["registrations"] = dataSet.eventRegistrations
        self.calendar.addEvent(eventData)
        self.calculateEventCalendarRegistrationsRemains(dataSet.eventMaximum, dataSet.eventRegistrations, elementRemain)
      })
      this.calendar.render()
      this.calendar.gotoDate(self.calendarStartDateTarget.value)
    }
  }

  connect() {
    this.newUserModalOpened = false
  }

  changeDateItem(event) {
    let dateItem = event.currentTarget
    dateItem.closest(".section-schedule").querySelectorAll(".date-item").forEach((item, i) => {
      item.classList.remove("active")
    })
    dateItem.classList.add("active")
    dateItem.closest(".section-schedule").querySelectorAll(".talks:not(.talks-premium)").forEach((item, i) => {
      item.classList.remove("active")
    })
    dateItem.closest(".section-schedule").querySelector(".talks[data-day='" + dateItem.getAttribute("data-day") + "']").classList.add("active")
  }

  calculateEventCalendarRegistrationsRemains(maximum, registrations, outerElement) {
    if (maximum && maximum.length && registrations) {
      let remainRegistrations = maximum - registrations
      outerElement.classList.remove("is-display-none")
      outerElement.querySelector(".event-remain-registrations-value").innerText = remainRegistrations
    } else
      outerElement.classList.add("is-display-none")
  }

  // Error form
  onPostError(event) {
    document.getElementById('registrations').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    this.formErrorsTarget.innerText = ""
    const [data, status, xhr] = event.detail
    for (var i = 0; i < JSON.parse(xhr.response).length; i++) {
      var error = JSON.parse(xhr.response)[i]
      var li = document.createElement("li")
      li.appendChild(document.createTextNode(error))
      this.formErrorsTarget.appendChild(li)
    }
  }

  // Success form
  onPostSuccess(event) {
    this.formErrorsTarget.innerText = ""
    let [data, status, xhr] = event.detail
  }

  onMouseOut(event) {
    if (event.clientY < 10 && document.querySelector('#new-user-form') && document.querySelector('#new-user-form').length && !this.newUserModalOpened) {
      this.newUserModalOpened = true
      this.newUserModalTarget.style.display = 'block'
    }
  }

  changeCountry(event) {
    if (event.target.value) {
      fetch(`/paises/${event.target.value}/estados`, {}
      ).then(res => {
        return res.json()
      }).then(data => {
        let select = document.querySelector(event.target.dataset.target)
        select.innerHTML = ""
        var option = document.createElement("option")
        option.text = ""
        option.value = " "
        select.appendChild(option)
        data.forEach((item) => {
          var option = document.createElement("option")
          option.text = item.name
          option.value = item.id
          select.appendChild(option)
        })
      })
    }
  }

  changeProvince(event) {
    if (event.target.value) {
      fetch(`/estados/${event.target.value}/cidades`, {}
      ).then(res => {
        return res.json()
      }).then(data => {
        let select = document.querySelector(event.target.dataset.target)
        select.innerHTML = ""
        var option = document.createElement("option")
        option.text = ""
        option.value = " "
        select.appendChild(option)
        data.forEach((item) => {
          var option = document.createElement("option")
          option.text = item.name
          option.value = item.id
          select.appendChild(option)
        })
      })
    }
  }

  openEventFormQuestions(event) {
    let eventEditionId = event.currentTarget.dataset.eventEditionId
    let eventEditionsUserId = event.currentTarget.dataset.eventEditionsUserId
    let eventId = event.currentTarget.dataset.eventId
    let answerForm = document.querySelector("#form-edition-form-answer")
    answerForm.action = `/${eventId}/respostas/`
    let inputEventEditionsUserId = document.querySelector("#event-editions-user-id")
    inputEventEditionsUserId.value = eventEditionsUserId
    this.eventFormQuestionsModalTarget.style.display = "block"
    this.eventFormQuestionsContentTarget.innerHTML = ""
    self = this
    fetch(`/edicoes/${eventEditionId}/perguntas`, {}
    ).then(res => {
      return res.json()
    }).then(data => {      
      data.forEach((item) => {
        let divFlex = document.createElement("div")
        divFlex.classList.add("flex")
        let divFlexItem = document.createElement("div")
        divFlexItem.classList.add("flex-item", "form-group")
        let input = ""
        let inputRequired = document.createElement("input")
        inputRequired.type = "hidden"
        inputRequired.name = "event_editions_user[event_form_answers_attributes][][required]"
        inputRequired.value = item.required
        let inputQuestion = document.createElement("input")
        inputQuestion.type = "hidden"
        inputQuestion.name = "event_editions_user[event_form_answers_attributes][][question]"
        inputQuestion.value = item.question
        let inputQuestionId = document.createElement("input")
        inputQuestionId.type = "hidden"
        inputQuestionId.name = "event_editions_user[event_form_answers_attributes][][event_form_question_id]"
        inputQuestionId.value = item.id
        if (item.question_type == "discursive") {
          if (item.field_type == "text") {
            input = document.createElement("textarea")
            input.rows = "1"
          } else if (item.field_type == "string") {
            input = document.createElement("input")
            input.type = "text"
          } else if (item.field_type == "integer" || item.field_type == "decimal") {
            input = document.createElement("input")
            input.type = "text"
          }
          input.classList.add("form-control", "input-field")
          input.name = "event_editions_user[event_form_answers_attributes][][answer]"
          input.placeholder = item.question
        } else if (item.question_type == "objective") {
          input = document.createElement("select")
          input.name = "event_editions_user[event_form_answers_attributes][][answer]"
          input.classList.add("form-control")
          let option = document.createElement("option")
          option.value = ""
          option.text = item.question
          input.appendChild(option)
          item.event_form_question_options.forEach((itemOption) => {
            let option = document.createElement("option")
            option.value = itemOption.option
            option.text = itemOption.option
            input.appendChild(option)
          })
        }
        if (input != "") {
          divFlex.appendChild(divFlexItem)
          divFlexItem.appendChild(inputRequired)
          divFlexItem.appendChild(inputQuestion)
          divFlexItem.appendChild(inputQuestionId)
          divFlexItem.appendChild(input)
          self.eventFormQuestionsContentTarget.appendChild(divFlex)
        }
      })
    })
  }

  togglePendingPresentations(event) {
    let pendingPresentationsWrapper = document.querySelector(`.pending-presentations-wrapper[data-product-id='${event.currentTarget.dataset.productId}']`)
    if (pendingPresentationsWrapper.classList.contains('is-display-none'))
      pendingPresentationsWrapper.classList.remove('is-display-none')
    else
      pendingPresentationsWrapper.classList.add('is-display-none')
  }

  toggleConnectedTransactions(event) {
    let connectedTransactionsWrapper = document.querySelector(`.connected-transactions-wrapper[data-transaction-id='${event.currentTarget.dataset.transactionId}']`)
    if (connectedTransactionsWrapper.classList.contains('is-display-none')) {
      event.currentTarget.querySelector("i.icon").classList.remove("icon-c-add")
      event.currentTarget.querySelector("i.icon").classList.add("icon-c-delete")
      connectedTransactionsWrapper.classList.remove('is-display-none')
    } else {
      event.currentTarget.querySelector("i.icon").classList.add("icon-c-add")
      event.currentTarget.querySelector("i.icon").classList.remove("icon-c-delete")
      connectedTransactionsWrapper.classList.add('is-display-none')
    }
  }

  beforeToggleEventEditionProducts(event) {
    var eventDetails = document.querySelector(`.event-edition-products-wrapper[data-event-edition-id='${event.currentTarget.dataset.eventEditionId}']`)
    if (eventDetails.innerHTML.trim().length > 0) {
      document.querySelectorAll(`.event-edition-products-button[data-event-edition-id='${event.currentTarget.dataset.eventEditionId}']`).forEach(function(item){ 
        item.classList.remove("disabled")
      })
      event.preventDefault()
    }
  }

  toggleEventEditionProducts(event) {
    let eventEditionProductsWrappers = document.querySelectorAll(".event-edition-products-wrapper")
    let eventEditionProductsWrapper = document.querySelector(`.event-edition-products-wrapper[data-event-edition-id='${event.currentTarget.dataset.eventEditionId}']`)
    let icon
    document.querySelectorAll(`.event-edition-products-button[data-event-edition-id='${event.currentTarget.dataset.eventEditionId}']`).forEach(function(item){ 
      item.classList.add("disabled")
      if (item.querySelector("i.icon"))
        icon = item.querySelector("i.icon")
    })
    eventEditionProductsWrappers.forEach((item, i) => {
      if (item !== eventEditionProductsWrapper) {
        item.classList.add("is-display-none")
        item.parentElement.querySelector("i.icon").classList.remove("icon-c-delete")
        item.parentElement.querySelector("i.icon").classList.add("icon-c-add")
      }
    })
    if (eventEditionProductsWrapper.classList.contains('is-display-none')) {
      if (icon) {
        icon.classList.remove("icon-c-add")
        icon.classList.add("icon-c-delete")
      }
      eventEditionProductsWrapper.classList.remove("is-display-none")
    } else {
      if (icon) {
        icon.classList.add("icon-c-add")
        icon.classList.remove("icon-c-delete")
      }
      eventEditionProductsWrapper.classList.add("is-display-none")
    }
  }

  toggleArticleEvaluations(event){    
    let icon = event.currentTarget.querySelector("i.icon")
    if (icon.classList.contains("icon-c-add")){
      icon.classList.remove("icon-c-add")
      icon.classList.add("icon-c-delete")
    } else {
      icon.classList.add("icon-c-add")
      icon.classList.remove("icon-c-delete")
    }

    let div = event.currentTarget.parentElement.parentElement.parentElement.nextElementSibling    
    if (div.classList.contains("is-display-none")){
      div.classList.remove("is-display-none")
    } else {
      div.classList.add("is-display-none")
    }
  }

  loadCKEditor(articleId, kind) {
    if (document.querySelector("#article_resume-" + articleId)) {
      let wordMaxLimit = document.getElementById("article-words-limit-" + articleId).getAttribute("data-value")
      let insertConfig = []
      if (kind == "0") {        
        insertConfig = []
      } else if (kind == "1") {        
        insertConfig = ['base64image', 'Table']
      } else if (kind == "2") {        
        insertConfig = ['base64image', 'Table']
      }
      
      let toolbarConfig = [
        { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
        { name: 'styles', items: [ 'Font', 'FontSize' ] },
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat', 'CopyFormatting' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
        { name: 'links', items: [ 'Link', 'Unlink' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent' ] },
        { name: 'insert', items: insertConfig },
        { name: 'editing', items: [ 'Scayt' ] }
      ]

      let config = {
        customConfig: '/js/ckeditor_config.js',
        toolbar: toolbarConfig,
        wordcount: {
          showWordCount: true,
          showCharCount: false,
          maxWordCount: wordMaxLimit
        }
      }      
      if (CKEDITOR.instances.hasOwnProperty("#article_resume-" + articleId)) {
        CKEDITOR.instances.article_resume.destroy()
      }
      CKEDITOR.replace(document.querySelector("#article_resume-" + articleId), config)
    }
  }  

  loadArticlesSetup(el){
    let articleId = el.currentTarget.getAttribute("data-value")
    let kind = document.getElementById("kind-" + articleId).value
    this.loadCKEditor(articleId, kind)
  }
  
  addEventCalendar(event) {
    self = this
    var r = confirm("Deseja reservar sua vaga nesta programação?")
    if (r == true) {
      let allEvents = document.querySelectorAll(".fc-event > .event-buttons > a")
      let targetToAddEvents = document.querySelector("#event-edition-calendar-added .fc-event:last-of-type") || document.querySelector("#event-edition-calendar-added p")
      let eventRefId = event.target.dataset.eventRefId
      let eventEditionId = event.target.dataset.eventEditionId
      let dataSet = event.target.dataset
      let eventData = {id: dataSet.eventId, title: dataSet.eventTitle, start: dataSet.eventStart, end: dataSet.eventEnd, extendedProps: {eventId: dataSet.eventRefId, eventEditionId: eventEditionId}}
      if (dataSet.eventMaximum)
        eventData["extendedProps"]["maximum"] = dataSet.eventMaximum

      allEvents.forEach(function(thisEvent){
        thisEvent.classList.add("disabled")
      })

      let body = {
        start_at: eventData.start,
        end_at: eventData.end,
        event_editions_presentation_id: eventData.id
      }

      fetch(`/${eventRefId}/edicoes/${eventEditionId}/calendarios-edicao`, {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      }).then(res => {
        if (res.ok)
          return res.json()
        alert('Não há mais vagas.')
        allEvents.forEach(function(thisEvent){
          thisEvent.classList.remove("disabled")
        })
        throw new Error('Something went wrong')
      }).then(data => {
        eventData.extendedProps.eventEditionCalendarId = data.event_edition_calendar.id
        if (dataSet.eventMaximum)
          eventData["extendedProps"]["registrations"] = data.registrations

        self.calendar.changeView('timeGridWeek')
        self.calendar.gotoDate(eventData.start)
        self.calendar.addEvent(eventData)
        event.target.classList.remove("event-add-button")
        event.target.classList.add("event-remove-button")
        event.target.dataset.action = "click->users#removeEventCalendar"
        event.target.dataset.eventEditionCalendarId = data.event_edition_calendar.id
        event.target.innerText = "Remover"
        event.target.closest(".fc-event").querySelector(".fc-event-main").dataset.action = "click->users#goToEventCalendar"
        targetToAddEvents.after(event.target.closest(".fc-event"))

        allEvents.forEach(function(thisEvent){
          thisEvent.classList.remove("disabled")
        })
      })
    }
  }

  removeEventCalendar(event) {
    var r = confirm("Deseja cancelar sua reserva nesta programação?")
    if (r == true) {
      let targetToGetEvents = document.querySelector("#event-edition-calendar-draggable")
      let eventData = this.calendar.getEventById(event.target.dataset.eventId)
      fetch(`/${event.target.dataset.eventId}/edicoes/${event.target.dataset.eventEditionId}/calendarios-edicao/${event.target.dataset.eventEditionCalendarId}`, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          "Content-Type": "application/json"
        }
      }).then(res => {
        return res.json()
      }).then(data => {
        let eventFromAdded = document.querySelector(`#event-edition-calendar-added .event-remove-button[data-event-edition-calendar-id="${data.id}"]`)
        eventFromAdded.dataset.action = "click->users#addEventCalendar"
        eventFromAdded.innerText = eventFromAdded.dataset.eventMaximum ? "Reservar" : "Colocar na agenda"
        delete eventFromAdded.closest(".fc-event").querySelector(".fc-event-main").dataset.action
        targetToGetEvents.append(eventFromAdded.closest(".fc-event"))
        self.calendar.changeView('timeGridWeek')
        self.calendar.gotoDate(eventData.start)
        eventData.remove()
      })
    }
  }

  goToEventCalendar(event) {
    let eventData = this.calendar.getEventById(event.target.closest(".fc-event").querySelector(".event-remove-button").dataset.eventId)
    self.calendar.changeView('timeGridWeek')
    self.calendar.gotoDate(eventData.start)
  }

  startCalendarExport(event, eventId, eventEditionId) {
    var r = confirm("Confirmar a exportação da agenda? Atenção: Se um conteúdo for alterado na programação, será necessário exportar a agenda novamente.")
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch(`/${eventId}/edicoes/${eventEditionId}/exportar-calendarios`)
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com a agenda.')
        event.target.style.pointerEvents = null
      })
    }
  }

  openResendModal(event){
    let currentArticleId = event.currentTarget.dataset.value
    let modal = document.getElementById("article-modal-" + currentArticleId)
    modal.style.display = "block"
  }
}
