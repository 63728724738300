// JS Controller for global methods and functions
import { Controller } from "stimulus"
import consumer from "../channels/consumer"
import Twitch from "../packs/twitch"
// import Dacast from "../packs/dacast"
import Rails from "@rails/ujs"
const YTPlayer = require('yt-player')

export default class extends Controller {
  static targets = ["input", "comments", "showComments", "noComments", "days", "hours", "minutes", "seconds", "source", "player", "channelName", "finishUrl", "twitchOfflineOrEndedBanner", "twitchOfflineOrEndedTitle", "twitchOfflineOrEndedSubtitle", "twitchOfflineOrEndedIcon", "iframePlayer"]

  connect() {    
    if (this.showCommentsTarget.getAttribute("data-show-comments") == "true"){
      this.channel = consumer.subscriptions.create({channel: "CommentChannel", presentation_video_url: this.showCommentsTarget.getAttribute("data-presentation-video-url")}, {
        connected: this._cableConnected.bind(this),
        disconnected: this._cableDisconnected.bind(this),
        received: this._cableReceived.bind(this),
      })
    } else {
      this.channel = consumer.disconnect()
    }
    
    if (document.getElementsByClassName('countdown').length > 0){
      if ((document.body.getAttribute("data-action").includes("broadcast_room")) && (document.querySelector(".countdown").getAttribute("data-has-in-future").includes("true"))){
        this.setCountDown()
      }
    }    
  
    if ((this.hasSourceTarget) && (this.hasChannelNameTarget) && document.body.getAttribute("data-action").includes("broadcast_room") && (this.sourceTarget.value == "1")){
      this.loadTwitchPlayer()
    }

    // if ((this.hasIframePlayerTarget) && (this.hasShowCommentsTarget) && ((this.showCommentsTarget.getAttribute("data-presentation-video-url") && (this.showCommentsTarget.getAttribute("data-presentation-video-url").includes('dacast'))))){
    //   console.log("DACAST PLAYER")
    //   this.loadDaCastPlayer()      
    // }    
    if ((this.hasIframePlayerTarget) && (this.iframePlayerTarget.dataset.source == "youtube") && (this.iframePlayerTarget.dataset.deliverytype == "1")){
      this.youtubePlayer = new YTPlayer(this.iframePlayerTarget)
      this.getYoutubeVideoId(this.iframePlayerTarget.src)
      this.youtubePlayer.load(this.getYoutubeVideoId(this.iframePlayerTarget.src))
      this.youtubePlayer.on('playing', () => {        
        if (!(navigator.userAgentData.mobile)){
          if (window.innerWidth >= 1200){
            this.iframePlayerTarget.style.height = 417 + "px"
          } else if (window.innerWidth <= 576) {
            this.iframePlayerTarget.style.height = 215 + "px"
          } else {
            this.iframePlayerTarget.style.height = 369 + "px"
          }
        }
      })
      this.youtubePlayer.on('paused', () => {        
        if (!(navigator.userAgentData.mobile)){
          if (window.innerWidth >= 1200){
            this.iframePlayerTarget.style.height = 588 + "px"
          } else if (window.innerWidth <= 576) {
            this.iframePlayerTarget.style.height = 369 + "px"
          } else {
            this.iframePlayerTarget.style.height = 417 + "px"
          }          
        }
      })
      this.youtubePlayer.on('ended', () => {        
        if (!(navigator.userAgentData.mobile)){
          this.iframePlayerTarget.style.height = 1410 + "px"
        }      
      })
    }
  }

  getYoutubeVideoId(url){
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
  }

  // loadDaCastPlayer(){    
  //   console.log("loadDaCastPlayer")
  //   let CONTENT_ID = "1552_f_297509"
  //   var myPlayer = dacast(CONTENT_ID, this.iframePlayerTarget, { 
  //     width: 740, 
  //     height: 417 
  //   });
  //   myPlayer.onPlay(function(){
  //     console.log("onPlay")
  //   })
    
  //   myPlayer.onPause(function(){
  //     console.log("TonPause")
  //   })

  //   myPlayer.on("complete", function(){
  //     console.log("ON")
  //   })

  //   myPlayer.off("complete", function(){
  //     console.log("OFF")
  //   })
  // }

  loadTwitchPlayer(){
    var options = {
      width: 740,
      height: 417,
      channel: this.channelNameTarget.value,
      controls: false,
      parent: ["eventos.congresse.me", "preview-client.congresse.me", "dashboard.congresse.me", "preview-dashboard.congresse.me", "localhost"]
    }        
    var player = new Twitch.Player(this.playerTarget, options)
    player._iframe.classList.add("player")
    player._iframe.setAttribute('allow', 'fullscreen')
    player._iframe.setAttribute('allowFullScreen', '')
    player._iframe.style.width = 740 + "px"
    player._iframe.style.height = 417 + "px"

    player.addEventListener(Twitch.Player.ONLINE, this.handleOnline)
    player.addEventListener(Twitch.Player.OFFLINE, this.handleOffline)
    // player.addEventListener(Twitch.Player.READY, this.handleReady)
    player.addEventListener(Twitch.Player.ENDED, this.handleEnded)
  }
  
  handleOnline(){    
    document.querySelector("[data-target='presentations.player']").classList.remove("is-display-none")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedBanner']").classList.add("is-display-none")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedTitle']").innerText = ""
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedSubtitle']").innerText = ""
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedIcon']").classList.remove("fa-toggle-off")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedIcon']").classList.remove("fa-window-close-o")
  }

  handleOffline(){
    document.querySelector("[data-target='presentations.player']").classList.add("is-display-none")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedBanner']").classList.remove("is-display-none")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedIcon']").classList.remove("fa-toggle-off")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedIcon']").classList.add("fa-window-close-o")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedTitle']").innerText = "Oops!"
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedSubtitle']").innerText = "Não estamos transmitindo no momento."
  }

  // handleReady(){
    
  // }

  handleEnded(){
    document.querySelector("[data-target='presentations.player']").classList.add("is-display-none")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedBanner']").classList.remove("is-display-none")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedIcon']").classList.remove("fa-window-close-o")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedIcon']").classList.add("fa-toggle-off")
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedTitle']").innerText = "Oops!"
    document.querySelector("[data-target='presentations.twitchOfflineOrEndedSubtitle']").innerText = "Esta transmissão foi encerrada."
  }

  clearInput() {
    this.inputTarget.value = ""
    document.querySelector('.messages').innerHTML = ""
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    let comment = JSON.parse(data.message)
    let commentDiv = document.createElement("div")
    commentDiv.classList.add("flex")
    commentDiv.classList.add("jc-between")
    commentDiv.classList.add("ai-center")
    commentDiv.classList.add("comment")
    let commenterDiv = document.createElement("div")
    commenterDiv.classList.add("commenter")
    let nameSpan = document.createElement("span")
    nameSpan.appendChild(document.createTextNode(comment.user_name.split("|")[0].trim()))
    let dateSpan = document.createElement("span")
    dateSpan.classList.add("date")
    dateSpan.appendChild(document.createTextNode(comment.created_at_timezone))
    let textP = document.createElement("p")
    textP.appendChild(this.stringToHTML(comment.text))
    commenterDiv.appendChild(nameSpan)
    commenterDiv.appendChild(dateSpan)
    commenterDiv.appendChild(textP)
    commentDiv.appendChild(commenterDiv)
    this.commentsTarget.prepend(commentDiv)

    if (document.querySelector("div[data-target='presentations.noComments']")){
      this.noCommentsTarget.remove()
    }
  }

  support() {
    if (!window.DOMParser) return false
    var parser = new DOMParser()
    try {
      parser.parseFromString('x', 'text/html')
    } catch(err) {
      return false
    }
    return true
  }
  
  stringToHTML(str) {    
    if (this.support()) {
      var parser = new DOMParser()
      var doc = parser.parseFromString(str, 'text/html')
      return doc.body
    }    
    var dom = document.createElement('div')
    dom.innerHTML = str
    return dom
  }

  setCountDown() {
    const startAt = document.querySelector(".countdown").getAttribute("data-start-at");
    let countDownDate;
    try {
      const date = new Date(startAt.endsWith(" UTC") ? startAt.replace(' UTC', 'Z') : startAt.replace(' ', 'T').replace(' -', '-'));
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", startAt);
      } else {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const options = { timeZone: userTimeZone, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        countDownDate = new Intl.DateTimeFormat('en-US', options).format(date);
      }
    } catch (error) {
      console.error("Error parsing date:", startAt);
    }
    
    countDownDate = new Date(countDownDate).getTime();
    
    // Update the count down every 1 second
    var daysHtml = this.daysTarget
    var hoursHtml = this.hoursTarget
    var minutesHtml = this.minutesTarget
    var secondsHtml = this.secondsTarget
    self = this
    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (days < 10) {
        days = "0" + days
      }
      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }
      if (seconds < 10) {
        seconds = "0" + seconds
      }
      if (parseInt(days) == 0){
        days = "0"
      }
      if (parseInt(hours) == 0){
        hours = "0"
      }
      if (parseInt(minutes) == 0){
        minutes = "0"
      }
      if (parseInt(seconds) == 0){
        seconds = "0"
      }
      daysHtml.innerHTML = days
      hoursHtml.innerHTML = hours
      minutesHtml.innerHTML = minutes
      secondsHtml.innerHTML = seconds      
      if ((days == "0") && (hours == "0") && (minutes == "0") && (seconds == "0")) {         
        clearInterval(x)        
        document.getElementById("countdownText").innerHTML = ""
        document.getElementById("countdownTime").classList.add("is-display-none")
        document.getElementById("refresh-button").classList.remove("is-display-none")
        let randomTime = self.getRandomInt(1000, 59000)        
        self.timeOutRefresh = setTimeout(function(e) {          
          self.refreshPlayerIframe()
        }, randomTime)
      }
      if (distance < 0) {
        clearInterval(x)
      }
    }, 1000)
  }

  getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)    
    let time = Math.floor(Math.random() * (max - min)) + min    
    return time
  } 

  refreshPlayerIframe(event){    
    if (event){
      event.preventDefault()
    }    
    clearTimeout(this.timeOutRefresh)
    Rails.ajax({
      url: this.finishUrlTarget.getAttribute("data-value"),
      type: "get",
      success: function(){        
        if ((self.hasSourceTarget) && (self.hasChannelNameTarget) && document.body.getAttribute("data-action").includes("broadcast_room") && (self.sourceTarget.value == "1")){
          self.loadTwitchPlayer()
        }
      }
    })
  }
}
