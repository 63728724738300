import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "slideCustomer" ]

  initialize() {
    this.showCurrentSlide()
  }

  next() {
    this.index++
  }

  previous() {
    this.index--
  }

  showCurrentSlide() {
    let size = document.querySelectorAll(".slide-customers .slide").length - 1
    if (this.index > size) {
      this.index = 0
    } else if (this.index < 0) {
      this.index = size
    }
    this.slideCustomerTargets.forEach((el, i) => {
      el.classList.toggle("slide--current", this.index == i)
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
    this.showCurrentSlide()
  }
}
