import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dropdownTranslation", "listTranslation"];

  connect() {
    this.dropdownTranslationTarget.addEventListener("click", () => {
      this.listTranslationTarget.classList.toggle("show-translation");
    });

    document.addEventListener("click", (event) => {
      if (!this.dropdownTranslationTarget.contains(event.target)) {
        this.listTranslationTarget.classList.remove("show-translation");
      }
    });
  }
  setLanguage(e) {    
    let defaultLanguage = 'en|pt';
    
    let clickedLanguage = e.currentTarget.getAttribute('data-value');

    if ((clickedLanguage != undefined) && (clickedLanguage != '')) {   
        defaultLanguage = 'en|' + clickedLanguage;
    } 

    let counter = 0;
    const numberOfCalls = 3;  
    const interval = 1000;

    const intervalId = setInterval(() => {
        if (counter < numberOfCalls) {
            doGTranslate(defaultLanguage);
            counter++;
        } else {
            clearInterval(intervalId);
        }
    }, interval);

    return false;
  }
} 