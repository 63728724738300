// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {

  static targets = ["messages"]

  connect() {
  }

  // Success contact form
  onPostSuccess(event) {
    let [data, status, xhr] = event.detail
    let responseJson = JSON.parse(xhr.responseText)
    if (responseJson.agreement_file_url) {
      this.messagesTarget.innerText = ""
      let [data, status, xhr] = event.detail
      var p = document.createElement("p")
      p.classList.add("success")
      p.appendChild(document.createTextNode("Documento encontrado, seu download começará em breve."))
      this.messagesTarget.appendChild(p)

      document.querySelector("#agreement_user_token").value = ""
      document.querySelector("#agreement_speaker_email").value = ""

      var link = document.createElement("a");
      link.setAttribute('download', name);
      link.href = responseJson.agreement_file_url;
      link.target = "_blank"
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
}