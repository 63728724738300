// JS Controller for global methods and functions

import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {

  static targets = [ "authors", "shortResume", "extendedResume", "monographyResume", "shortResumeLimit", "extendedResumeLimit", "monographyResumeLimit", "shortResumeMaxSize", "extendedResumeMaxSize", "wordsSize", "monographyResumeMaxSize", "eventEditionId", "articleModalityIds"]
  static values = [ "resumeEditor" ]
  
  connect() {
    if (document.body.getAttribute("data-action").includes("new")) {
      this.hideOrShowResumeField(document.getElementById("article_article_type").value)
      this.hideOrShowResumeTargets(document.getElementById("article_article_type").value)
      this.loadArticleModalities(document.getElementById("article_article_type").value)
      this.loadCKEditor()
    }

    if (document.querySelector("#article_keywords")) {
      this.loadArticleKeywords()
    }
  }

  loadArticleModalities(value){  
    self = this    
    if (document.querySelector('#article_article_modality_ids')){    
      self.articleModalityIdsTarget.innerHTML = ""  
      if (value != ''){                    
        fetch(
          "/modalidades-de-resumos/listar-todos?event_edition_id=" + self.eventEditionIdTarget.dataset.value + "&article_type=" + value
        )
        .then(function(response) {
          return response.json()
        })
        .then(function(data) {
          data.map(function(articleModality) {
            self.articleModalityIdsTarget.insertAdjacentHTML('beforeend', `
              <option value="${articleModality.value}">${articleModality.label}</option>
            `)
          })
        })
      }     
    }
  }
  hideOrShowResumeField(value) {    
    if (document.querySelector(".messages")) {
      document.querySelector(".messages").innerHTML = ""
    }      
    if (document.querySelector("#article_archive")) {
      var enableShortResumePdf = document.querySelector("#article_archive").dataset.enableShortResumePdf
      var enableExtendedResumePdf = document.querySelector("#article_archive").dataset.enableExtendedResumePdf
      var enableMonographyPdf = document.querySelector("#article_archive").dataset.enableMonographyPdf
    }
    if (value == "0") {
      if (enableShortResumePdf == "true") {
        if (document.querySelector("#article_archive")) {
          document.querySelector("#article_archive").classList.add("is-display-block")
          document.querySelector("#article_archive_label").classList.remove("is-display-none")
        }
        if (document.querySelector("#article_resume_field")) {
          document.querySelector("#article_resume_field").classList.add("is-display-none")
          document.querySelector("#article_resume_label").classList.add("is-display-none")
        }
      } else {
        if (document.querySelector("#article_archive")) {
          document.querySelector("#article_archive").classList.remove("is-display-block")
          document.querySelector("#article_archive_label").classList.add("is-display-none")
        }
        if (document.querySelector("#article_resume_field")) {
          document.querySelector("#article_resume_field").classList.remove("is-display-none")
          document.querySelector("#article_resume_label").classList.remove("is-display-none")
        }
      }
    } else if (value == "1") {
      if (enableExtendedResumePdf == "true") {
        if (document.querySelector("#article_archive")) {
          document.querySelector("#article_archive").classList.add("is-display-block")
          document.querySelector("#article_archive_label").classList.remove("is-display-none")
        }
        if (document.querySelector("#article_resume_field")) {
          document.querySelector("#article_resume_field").classList.add("is-display-none")
          document.querySelector("#article_resume_label").classList.add("is-display-none")
        }
      } else {
        if (document.querySelector("#article_archive")) {
          document.querySelector("#article_archive").classList.remove("is-display-block")
          document.querySelector("#article_archive_label").classList.add("is-display-none")
        }
        if (document.querySelector("#article_resume_field")) {
          document.querySelector("#article_resume_field").classList.remove("is-display-none")
          document.querySelector("#article_resume_label").classList.remove("is-display-none")
        }
      }
    } else if (value == "2") {
      if (enableMonographyPdf == "true") {
        if (document.querySelector("#article_archive")) {
          document.querySelector("#article_archive").classList.add("is-display-block")
          document.querySelector("#article_archive_label").classList.remove("is-display-none")
        }
        if (document.querySelector("#article_resume_field")) {
          document.querySelector("#article_resume_field").classList.add("is-display-none")
          document.querySelector("#article_resume_label").classList.add("is-display-none")
        }
      } else {
        if (document.querySelector("#article_archive")) {
          document.querySelector("#article_archive").classList.remove("is-display-block")
          document.querySelector("#article_archive_label").classList.add("is-display-none")
        }
        if (document.querySelector("#article_resume_field")) {
          document.querySelector("#article_resume_field").classList.remove("is-display-none")
          document.querySelector("#article_resume_label").classList.remove("is-display-none")
        }
      }
    }
  }

  hideOrShowResumeTargets(value) {
    if (value == ''){
      this.shortResumeTarget.classList.add("is-display-none")
      this.extendedResumeTarget.classList.add("is-display-none")
      this.monographyResumeTarget.classList.add("is-display-none")
    } else if (value == "0") {
      this.shortResumeTarget.classList.remove("is-display-none")
      this.extendedResumeTarget.classList.add("is-display-none")
      this.monographyResumeTarget.classList.add("is-display-none")
      if (this.hasShortResumeLimitTarget)
        this.shortResumeLimitTarget.classList.remove("is-display-none")
      if (this.hasExtendedResumeLimitTarget)
        this.extendedResumeLimitTarget.classList.add("is-display-none")
      if (this.hasMonographyResumeLimitTarget)
        this.monographyResumeLimitTarget.classList.add("is-display-none")
      if (document.getElementById("kind"))
        document.getElementById("kind").value = 0
    } else if (value == "1") {
      this.extendedResumeTarget.classList.remove("is-display-none")
      this.shortResumeTarget.classList.add("is-display-none")      
      this.monographyResumeTarget.classList.add("is-display-none")
      if (this.hasExtendedResumeLimitTarget)
        this.extendedResumeLimitTarget.classList.remove("is-display-none")
      if (this.hasShortResumeLimitTarget)
        this.shortResumeLimitTarget.classList.add("is-display-none")      
      if (this.hasMonographyResumeLimitTarget)
        this.monographyResumeLimitTarget.classList.add("is-display-none")
      if (document.getElementById("kind"))
        document.getElementById("kind").value = 1
    } else if (value == "2") {
      this.monographyResumeTarget.classList.remove("is-display-none")
      this.shortResumeTarget.classList.add("is-display-none")
      this.extendedResumeTarget.classList.add("is-display-none")  
      if (this.hasMonographyResumeLimitTarget)
        this.monographyResumeLimitTarget.classList.remove("is-display-none")    
      if (this.hasShortResumeLimitTarget)
        this.shortResumeLimitTarget.classList.add("is-display-none")
      if (this.hasExtendedResumeLimitTarget)
        this.extendedResumeLimitTarget.classList.add("is-display-none")      
      if (document.getElementById("kind"))
        document.getElementById("kind").value = 2
    }
  }

  addAuthor(event) {
    if (!(this.countAuthorsFields() >= 22)){
      var divItem = document.createElement("div")
      divItem.classList.add("item")
      var divFlex1 = document.createElement("div")
      divFlex1.classList.add("flex")
      var formGroup1 = document.createElement("div")
      formGroup1.classList.add("flex-item")
      formGroup1.classList.add("form-group")
      var input1 = document.createElement("input")
      input1.type = "text"
      input1.name = "article[names][]"
      input1.placeholder = (this.countAuthorsFields() + 2) + "º Autor (nome completo)"
      formGroup1.appendChild(input1)
      divFlex1.appendChild(formGroup1)
      var divFlex2 = document.createElement("div")
      divFlex2.classList.add("flex")
      var formGroup2 = document.createElement("div")
      formGroup2.classList.add("flex-item")
      formGroup2.classList.add("form-group")
      var input2 = document.createElement("input")
      input2.type = "text"
      input2.placeholder = (this.countAuthorsFields() + 2) + "º Instituição"
      input2.name = "article[institutions][]"
      formGroup2.appendChild(input2)
      var formGroup3 = document.createElement("div")
      formGroup3.classList.add("flex-item")
      formGroup3.classList.add("form-group")
      var input3 = document.createElement("input")
      input3.name = "article[emails][]"
      input3.type = "email"
      input3.placeholder = (this.countAuthorsFields() + 2) + "º Email"
      formGroup3.appendChild(input3)      
      var formGroup4 = document.createElement("div")
      formGroup4.classList.add("flex-item")
      formGroup4.classList.add("form-group")
      var input4 = document.createElement("input")
      input4.name = "article[whatsapp_numbers][]"
      input4.type = "text"
      input4.placeholder = (this.countAuthorsFields() + 2) + "º Whatsapp"
      formGroup4.appendChild(input4)
      divFlex2.appendChild(formGroup2)
      divFlex2.appendChild(formGroup3)
      divFlex2.appendChild(formGroup4)
      divItem.appendChild(divFlex1)
      divItem.appendChild(divFlex2)
      this.authorsTarget.append(divItem)
    }
  }

  removeAuthor(event) {
    const child = this.authorsTarget.lastElementChild
    if (child == null) {
      return false
    } else {
      this.authorsTarget.lastElementChild.remove()
    }
  }

  countAuthorsFields(){
    return this.authorsTarget.getElementsByClassName('item').length
  }

  changeArticleType(event) {
    this.hideOrShowResumeField(event.target.value)
    this.hideOrShowResumeTargets(event.target.value)
    this.loadArticleModalities(event.target.value)
    this.loadCKEditor()
  }

  loadArticleKeywords() {
    let config = {
      searchResultLimit: 15,
      searchChoices: false,
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }
    let input = document.querySelector("#article_keywords")
    let choice = new Choices(input, config)
    let inputDup = input.parentElement.getElementsByClassName("choices__input--cloned")[0]
    let keyboardEnter = new KeyboardEvent('keydown', {
      code: 'Enter',
      key: 'Enter',
      charKode: 13,
      keyCode: 13,
      view: window
    });
    inputDup.addEventListener('keyup', function(event) {
      let newValue = event.target.value.slice(0, -1)
      if (event.keyCode == 188 || event.keyCode == 186 || event.keyCode == 190) {
        event.target.value = newValue
        inputDup.dispatchEvent(keyboardEnter)
      }
    })
    inputDup.addEventListener('paste', function(event) {
      setTimeout(function(e) {
        event.target.value.split(/\s*,\s*|\s*;\s*|\s*\.\s*/).forEach(function(item){
          event.target.value = item
          inputDup.dispatchEvent(keyboardEnter)
        });
      }, 1);
    })
  }

  loadCKEditor() {
    if (document.querySelector("#article_resume")) {
      let wordMaxLimit = 0
      let insertConfig = []
      if (document.getElementById("article_article_type").value == "0") {
        wordMaxLimit = this.shortResumeMaxSizeTarget.getAttribute("data-value")
        insertConfig = []
      } else if (document.getElementById("article_article_type").value == "1") {
        wordMaxLimit = this.extendedResumeMaxSizeTarget.getAttribute("data-value")
        insertConfig = ['base64image', 'Table']
      } else if (document.getElementById("article_article_type").value == "2") {
        wordMaxLimit = this.monographyResumeMaxSizeTarget.getAttribute("data-value")
        insertConfig = ['base64image', 'Table']
      }
      
      let toolbarConfig = [
        { name: 'clipboard', items: [ 'Undo', 'Redo' ] },
        { name: 'styles', items: [ 'Font', 'FontSize' ] },
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat', 'CopyFormatting' ] },
        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
        { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
        { name: 'links', items: [ 'Link', 'Unlink' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent' ] },
        { name: 'insert', items: insertConfig },
        { name: 'editing', items: [ 'Scayt' ] }
      ]

      let config = {
        customConfig: '/js/ckeditor_config.js',
        toolbar: toolbarConfig,
        wordcount: {
          showWordCount: true,
          showCharCount: false,
          maxWordCount: wordMaxLimit
        }
      }

      if (CKEDITOR.instances.hasOwnProperty("article_resume")) {
        CKEDITOR.instances.article_resume.destroy()
      }
      CKEDITOR.replace(document.querySelector('#article_resume'), config)
    }
  }
}
