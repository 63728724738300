import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import consumer from "../channels/consumer"
import pagarme from 'pagarme/browser'

export default class extends Controller {
  static targets = [ "breadcrumbUser", "breadcrumbAddress", "breadcrumbPayment",
   "userData", "addressData", "paymentData", "creditCardData", "totalAmount", "price",
   "installments", "formErrors", "userPasswordModal", "checkoutLoadingModal",
   "couponCode", "productId", "eventEditionId", "installmentsList", "couponCodeDetails",
   "phoneNumber", "transactionId", "transactionMethod", "billetDownloadButton",
   "billetBarcode", "productCategory", "paymentDataForm"]

  connect() {
    if (this.hasUserPasswordModalTarget && this.userPasswordModalTarget.dataset.open == "true") {
      this.userPasswordModalTarget.style.display = "block"
    }

    if (document.querySelector("#country_id") && document.querySelector("#country_id").value) {
      this.paymentMethod(document.querySelector("#country_id").options[document.querySelector("#country_id").selectedIndex].getAttribute('data-shortcode'))
    }
    if (document.querySelector("#installments")) {
      this.changeInstallments()
    }

    if (document.querySelector("[data-target='products.transactionId']")) {
      if (this.transactionMethodTarget.getAttribute("data-value") == "boleto"){
        this.checkoutLoadingModalTarget.style.display = "block"
        this.channel = consumer.subscriptions.create(
          {
            channel: "TransactionChannel",
            transaction_id: this.transactionIdTarget.getAttribute("data-value"),
            random_integer: Math.floor(Math.random() * (1000000 - 1)).toString()
          }, {
          connected: this._cableConnected.bind(this),
          disconnected: this._cableDisconnected.bind(this),
          received: this._cableReceived.bind(this),
        })
        //this.getRequest("/transacoes/" + this.transactionIdTarget.getAttribute("data-value") + "/detalhes")
      }
    }
    if (document.body.getAttribute("data-action").includes("checkout")) {
      // this.sendAbandonedCart
      setTimeout(this.sendAbandonedCart, 10800000)
    }

    if (this.productCategoryTarget.getAttribute("data-value") == 'article'){
      this.loadCKEditor()
    }
  }

  sendAbandonedCart(){
     if ((document.querySelector("#name").value != "") && (document.querySelector("#phone_number").value) && (document.querySelector("#email").value.match(/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/))) {
      let body = {
        event: "abandoned-cart",
        name: document.querySelector("#name").value,
        email: document.querySelector("#email").value,
        phone_number: document.querySelector("#phone_number").value,
        event_edition_id: document.querySelector('div[data-target="products.eventEditionId"]').getAttribute("data-value"),
        product_id: document.querySelector('div[data-target="products.productId"]').getAttribute("data-value")
      }
      fetch("/send-notification",
        {
          headers: {
            "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
            "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify(body)
        }
      )
    }
  }

  copyCode() {
    let copyText = document.getElementById("code")

    copyText.select()

    document.execCommand("copy")
    alert("Código copiado: " + copyText.value)
  }

  openPasswordOrGoToUrl(event) {
    if (this.hasUserPasswordModalTarget) {
      this.userPasswordModalTarget.style.display = "block"
    } else {
      window.location = event.currentTarget.dataset.url
    }
  }

  selectPaymentMethod(event) {
    this.paymentMethod(event.currentTarget.options[event.currentTarget.selectedIndex].getAttribute('data-shortcode'))
    this.changePhoneNumberFormat(event.currentTarget.options[event.currentTarget.selectedIndex].getAttribute('data-shortcode'))
  }

  setNewCleave(shortCode){
    let cleave = new Cleave(this.phoneNumberTarget, {
      phone: true,
      phoneRegionCode: shortCode
    })
    return cleave
  }

  changePhoneNumberFormat(shortCode){
    this.phoneNumberTarget.value = ''
    if (shortCode == "br"){
      this.phoneNumberTarget.placeholder = "Ex.: 99 99999 9999"
      this.cleave = this.setNewCleave(shortCode.toUpperCase())
    } else {
      this.phoneNumberTarget.placeholder = "Ex.: +9 99 9999 9999"
      try { this.cleave.destroy() } catch (e) { }
    }
  }

  paymentMethod(shortCode) {
    let documentMethodSelect = document.querySelector("#document_type")
    let documentMethodLabel = document.querySelector("label[for=document_number]")
    let paymentMethodCreditCardOption = document.querySelector("#payment_method_credit_card")
    let paymentMethodBoletoOption = document.querySelector("#payment_method_boleto")
    let paymentMethodPixOption = document.querySelector("#payment_method_pix")

    if (shortCode == 'br') {
      documentMethodSelect.options[0].disabled = false
      documentMethodSelect.value = "cpf"
      documentMethodSelect.style.pointerEvents = "none"
      documentMethodSelect.parentElement.classList.remove("is-display-block")
      documentMethodSelect.parentElement.classList.add("is-display-none")
      paymentMethodBoletoOption.parentElement.style.display = "flex"
      paymentMethodPixOption.parentElement.style.display = "flex"
      documentMethodLabel.innerText = "CPF* (somente números)"
    } else {
      documentMethodSelect.options[0].disabled = true
      documentMethodSelect.value = "other"
      documentMethodSelect.style.pointerEvents = "auto"
      documentMethodSelect.parentElement.classList.remove("is-display-none")
      documentMethodSelect.parentElement.classList.add("is-display-block")
      paymentMethodCreditCardOption.checked = true
      paymentMethodCreditCardOption.dispatchEvent(new Event('change'));
      paymentMethodBoletoOption.parentElement.style.display = "none"
      paymentMethodPixOption.parentElement.style.display = "none"
      documentMethodLabel.innerText = "Número do documento*"
    }
  }

  changePaymentMethod(event) {
    this.formErrorsTarget.innerText = ""
    if (document.querySelector(".messages"))
      document.querySelector(".messages").innerHTML = ""

    if (event.currentTarget.value == "credit_card") {
      this.creditCardDataTarget.classList.remove("is-display-none")
    } else {
      this.creditCardDataTarget.classList.add("is-display-none")
      for(var i=0; i < this.creditCardDataTarget.getElementsByTagName('input').length; i++)
        this.creditCardDataTarget.getElementsByTagName('input')[i].value = '';
      document.querySelector('#installments').selectedIndex = null
      document.querySelector('#installments').dispatchEvent(new Event('change'));
    }
  }

  changeInstallments() {
    let amount = this.installmentsListTarget.options[this.installmentsListTarget.selectedIndex].dataset.amount || this.totalAmountTarget.dataset.price
    let price = (amount / 100).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
    this.totalAmountTarget.value = amount
    this.priceTarget.innerHTML = price.toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
    //if (this.installmentsListTarget.selectedIndex > 0)
    this.installmentsTarget.innerHTML = 'Pagamento em <strong>' + this.installmentsListTarget.options[this.installmentsListTarget.selectedIndex].innerHTML + '</strong>'
    // else
    //   this.installmentsTarget.innerHTML = ''
  }

  backToAddress() {
    this.formErrorsTarget.innerText = ""
    if (document.querySelector(".messages"))
      document.querySelector(".messages").innerHTML = ""
    if (this.hasBreadcrumbUserTarget)
      this.breadcrumbUserTarget.classList.remove("current")
    this.breadcrumbPaymentTarget.classList.remove("current")
    this.breadcrumbAddressTarget.classList.add("current")
    this.userDataTarget.classList.add("is-display-none")
    this.addressDataTarget.classList.remove("is-display-none")
    this.paymentDataTarget.classList.add("is-display-none")
  }

  backToUser() {
    this.formErrorsTarget.innerText = ""
    if (document.querySelector(".messages"))
      document.querySelector(".messages").innerHTML = ""
    if (this.hasBreadcrumbUserTarget)
      this.breadcrumbUserTarget.classList.add("current")
    this.breadcrumbPaymentTarget.classList.remove("current")
    this.breadcrumbAddressTarget.classList.remove("current")
    this.userDataTarget.classList.remove("is-display-none")
    this.addressDataTarget.classList.add("is-display-none")
    this.paymentDataTarget.classList.add("is-display-none")
  }

  nextToAddess() {
    self = this
    this.formErrorsTarget.innerText = ""
    if (document.querySelector(".messages"))
      document.querySelector(".messages").innerHTML = ""
    let addressFields = [
      { id: '#name', errorText: 'Nome não pode ficar vazio' },
      { id: '#degree_level', errorText: 'Nível de Formação não pode ficar vazio' },
      { id: '#email', errorText: 'Email não pode ficar vazio' },
      { id: '#email_confirmation', errorText: 'Confirmação de Email não pode ficar vazio' }
    ]
    addressFields.forEach(function(item){
      if (document.querySelector(item.id).value.trim().length == 0) {
        let error = item.errorText
        let li = document.createElement("li")
        li.appendChild(document.createTextNode(error))
        self.formErrorsTarget.appendChild(li)
      }
    })
    if (!document.querySelector("#email").value.match(/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) {
      let li = document.createElement("li")
      li.appendChild(document.createTextNode("Email inválido"))
      self.formErrorsTarget.appendChild(li)
    }
    if (document.querySelector("#email").value != document.querySelector("#email_confirmation").value) {
      let li = document.createElement("li")
      li.appendChild(document.createTextNode("O campo Confirmação de Email deve ser igual ao Email"))
      self.formErrorsTarget.appendChild(li)
    }
    if (this.formErrorsTarget.innerText != "") {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      this.breadcrumbUserTarget.classList.remove("current")
      this.breadcrumbPaymentTarget.classList.remove("current")
      this.breadcrumbAddressTarget.classList.add("current")
      this.userDataTarget.classList.add("is-display-none")
      this.addressDataTarget.classList.remove("is-display-none")
      this.paymentDataTarget.classList.add("is-display-none")
    }
  }

  nextToPayment() {
    self = this
    this.formErrorsTarget.innerText = ""
    if (document.querySelector(".messages"))
      document.querySelector(".messages").innerHTML = ""
    let addressFields = [
      { id: '#street', errorText: 'Endereço não pode ficar vazio' },
      { id: '#street_number', errorText: 'Número não pode ficar vazio' },
      { id: '#neighborhood', errorText: 'Bairro não pode ficar vazio' },
      { id: '#complement', errorText: 'Complemento não pode ficar vazio' },
      { id: '#zipcode', errorText: 'CEP não pode ficar vazio' },
      { id: '#country_id', errorText: 'País/Nação não pode ficar vazio' },
      { id: '#province_id', errorText: 'Estado não pode ficar vazio' },
      { id: '#city_id', errorText: 'Cidade não pode ficar vazio' },
      { id: '#phone_number', errorText: 'Telefone não pode ficar vazio' }
    ]
    let termsFields = [
      { id: '#accept_terms_and_privacy_policy', errorText: 'Para realizar o cadastro é necessário aceitar os termos de uso e a política de privacidade da plataforma' },
    ]
    addressFields.forEach(function(item){
      if (document.querySelector(item.id).value.trim().length == 0) {
        let error = item.errorText
        let li = document.createElement("li")
        li.appendChild(document.createTextNode(error))
        self.formErrorsTarget.appendChild(li)
      }
    });
    termsFields.forEach(function(item){
      if (!document.querySelector(item.id).checked) {
        let error = item.errorText
        let li = document.createElement("li")
        li.appendChild(document.createTextNode(error))
        self.formErrorsTarget.appendChild(li)
      }
    });
    if (document.querySelector("#country_id").options[document.querySelector("#country_id").selectedIndex].getAttribute('data-shortcode') == 'br') {
      if (!document.querySelector("#phone_number").value.match(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/)) {
        let li = document.createElement("li")
        li.appendChild(document.createTextNode("O telefone é inválido"))
        self.formErrorsTarget.appendChild(li)
      }

      if (document.querySelector("#zipcode").value && document.querySelector("#zipcode").value.length != 8) {
        let li = document.createElement("li")
        li.appendChild(document.createTextNode("O campo de CEP deve ter 8 dígitos"))
        self.formErrorsTarget.appendChild(li)
      }
    } else {
      if (!document.querySelector("#phone_number").value.match(/^\+(?:[0-9] ?){6,14}[0-9]$/)) {
        let li = document.createElement("li")
        li.appendChild(document.createTextNode("O telefone deve iniciar por +DDI. Ex.: +9 99 9999 9999"))
        self.formErrorsTarget.appendChild(li)
      }
    }
    if (this.formErrorsTarget.innerText != "") {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      this.breadcrumbAddressTarget.classList.remove("current")
      this.breadcrumbPaymentTarget.classList.add("current")
      this.paymentDataTarget.classList.remove("is-display-none")
      this.addressDataTarget.classList.add("is-display-none")
    }
  }

  finishPayment(event) {
    self = this
    event.target.disabled = true
    this.formErrorsTarget.innerText = ""
    if (document.querySelector(".messages"))
      document.querySelector(".messages").innerHTML = ""
    let paymentCCFields = [
      { id: '#card_holder', errorText: 'Nome do titular do cartão não pode ficar vazio' },
      { id: '#card_number', errorText: 'Número do cartão não pode ficar vazio' },
      // { id: '#card_expiration_date', errorText: 'Validade do cartão não pode ficar vazio' },
      { id: '#card_verification_code', errorText: 'Código de verificação não pode ficar vazio' },
      { id: '#installments', errorText: 'Parcelamento não pode ficar vazio' },
      { id: '#document_number', errorText: 'Número do documento não pode ficar vazio' }
    ]

    let articleFields = []
    if (self.productCategoryTarget.getAttribute("data-value") == 'article'){
      articleFields.push({id: '#articles_list', errorText: 'Informe o(s) resumo(s) a ser(em) contemplado(s) através deste pagamento' })
    }

    articleFields.forEach(function(item){
      if (CKEDITOR.instances.articles_list.getData().trim().length == 0) {
        let error = item.errorText
        let li = document.createElement("li")
        li.appendChild(document.createTextNode(error))
        self.formErrorsTarget.appendChild(li)
      }
    });

    let paymentBoletoFields = [
      { id: '#document_number', errorText: 'Número do documento não pode ficar vazio' }
    ]

    if (document.querySelector("#payment_method_credit_card").checked) {
      let card = {
        card_holder_name: document.querySelector("#card_holder").value,
        card_expiration_date: document.querySelector("#_card_expiration_date_2i").options[document.querySelector("#_card_expiration_date_2i").selectedIndex].text + '/' + document.querySelector("#_card_expiration_date_1i").value.substr(2, 2),
        card_number: document.querySelector("#card_number").value,
        card_cvv: document.querySelector("#card_verification_code").value
      }
      let cardValidations = pagarme.validate({card: card})

      paymentCCFields.forEach(function(item){
        if (document.querySelector(item.id).value.trim().length == 0) {
          let error = item.errorText
          let li = document.createElement("li")
          li.appendChild(document.createTextNode(error))
          self.formErrorsTarget.appendChild(li)
        }
      });

      if (card.card_expiration_date && !cardValidations.card.card_expiration_date) {
        let error = 'Validade do cartão inválida.'
        let li = document.createElement("li")
        li.appendChild(document.createTextNode(error))
        self.formErrorsTarget.appendChild(li)
      }

      if (card.card_number && !cardValidations.card.card_number) {
        let error = 'Número do cartão inválido.'
        let li = document.createElement("li")
        li.appendChild(document.createTextNode(error))
        self.formErrorsTarget.appendChild(li)
      }

      if (card.card_cvv && !cardValidations.card.card_cvv) {
        let error = 'Código de verificação inválido.'
        let li = document.createElement("li")
        li.appendChild(document.createTextNode(error))
        self.formErrorsTarget.appendChild(li)
      }
    } else if (document.querySelector("#payment_method_boleto").checked) {
      paymentBoletoFields.forEach(function(item){
        if (document.querySelector(item.id).value.trim().length == 0) {
          let error = item.errorText
          let li = document.createElement("li")
          li.appendChild(document.createTextNode(error))
          self.formErrorsTarget.appendChild(li)
        }
      });
    }
    if (this.formErrorsTarget.innerText != "") {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      event.target.disabled = false
    } else {
      let elem = document.getElementById('checkout-form')
      this.checkoutLoadingModalTarget.style.display = "block"
      Rails.fire(elem, 'submit')
    }
  }

  checkEnter(e) {
    let txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
    if (txtArea || (e.keyCode || e.which || e.charCode || 0) == 13) {
      e.preventDefault();
    }
  }

  onPaymentError() {
    document.querySelector("#finish-payment-button").disabled = false
    this.checkoutLoadingModalTarget.style.display = "none"
  }

  onPaymentSuccess() {
    document.querySelector("#finish-payment-button").disabled = false
    this.checkoutLoadingModalTarget.style.display = "none"
  }

  checkCouponCode() {
    let couponCode = this.couponCodeTarget.value
    let paymentMethod = document.querySelector('input[name="payment_method"]:checked').value
    let selectedInstallment = document.getElementById("installments").value
    let body = {
      coupon_code: couponCode,
      payment_method: paymentMethod,
      product_id: this.productIdTarget.getAttribute("data-value"),
      event_edition_id: this.eventEditionIdTarget.getAttribute("data-value")
    }

    if (paymentMethod == "credit_card") {
      body.selected_installment = selectedInstallment
    }

    fetch("/cupons/verificar/", {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }).then(res => {
      return res.json()
    }).then(data => {
      this.totalAmountTarget.value = data.amount
      this.installmentsListTarget.innerHTML = ""
      for(let i in data.installments){
        var opt = document.createElement('option')
        opt.value = data.installments[i].installment
        opt.setAttribute('data-amount', data.installments[i].amount)
        opt.innerHTML = data.installments[i].installment + "x de " + (data.installments[i].installment_amount / 100).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
        this.installmentsListTarget.appendChild(opt)
      }
      if (selectedInstallment) {
        this.installmentsListTarget.value = selectedInstallment
      }
      if (data.coupon.code && data.coupon.discount_percentage) {
        if (data.coupon.discount_percentage === 100) {
          document.querySelector("#payment_method_credit_card").checked = false;
          document.querySelector("#payment_method_boleto").checked = false;
          this.paymentDataFormTarget.classList.remove("is-display-block")
          this.paymentDataFormTarget.classList.add("is-display-none")
        } else {
          this.paymentDataFormTarget.classList.remove("is-display-none")
          this.paymentDataFormTarget.classList.add("is-display-block")
        }
        this.couponCodeDetailsTarget.classList.remove("is-display-none")
        this.couponCodeDetailsTarget.classList.add("is-display-block")
        this.couponCodeDetailsTarget.innerHTML = "Cupom aplicado: <strong>" + data.coupon.code + "</strong> | Desconto aplicado: <strong>" + data.coupon.discount_percentage + "%</strong>"
        this.couponCodeTarget.classList.remove("error")
        this.couponCodeTarget.classList.add("success")

        document.getElementById("coupon-message").innerHTML = "Desconto aplicado"
        document.getElementById("coupon-message").classList.remove("error")
        document.getElementById("coupon-message").classList.add("success")
        //document.getElementById("btn-apply-coupon").disabled = true
        setInterval(function(){
          document.getElementById("coupon_code").classList.remove("success")
          document.getElementById("coupon-message").innerHTML = ""
        }, 3000)
      } else {
        this.couponCodeDetailsTarget.classList.add("is-display-none")
        this.couponCodeDetailsTarget.classList.remove("is-display-block")
        this.couponCodeTarget.classList.remove("success")
        this.couponCodeTarget.classList.add("error")
        this.paymentDataFormTarget.classList.remove("is-display-none")
        this.paymentDataFormTarget.classList.add("is-display-block")
        document.getElementById("coupon-message").innerHTML = "Cupom inválido"
        document.getElementById("coupon-message").classList.remove("success")
        document.getElementById("coupon-message").classList.add("error")
        setInterval(function(){
          document.getElementById("coupon_code").classList.remove("error")
          document.getElementById("coupon-message").innerHTML = ""
        }, 3000)
        document.getElementById("coupon_code").value = ""
      }
      this.changeInstallments()
    })
  }

  async getRequest(url){
    const response = await fetch(url)
    return await response.json()
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    this.billetDownloadButtonTarget.setAttribute('href', data.boleto_url + '?format=pdf')
    this.billetBarcodeTarget.value = data.boleto_barcode
    this.channel = consumer.disconnect()
    this.checkoutLoadingModalTarget.style.display = "none"
  }

  loadCKEditor() {
    if (document.querySelector("#articles_list")) {

      let toolbarConfig = [
        { name: 'basicstyles', items: [ 'Bold', 'Italic' ] },
        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList' ] }
      ]

      let config = {
        toolbar: toolbarConfig,
      }

      if (CKEDITOR.instances.hasOwnProperty("articles_list")) {
        CKEDITOR.instances.articles_list.destroy()
      }
      CKEDITOR.replace(document.querySelector('#articles_list'), config)
    }
  }
}
