// JS Controller for global methods and functions

import { Controller } from "stimulus"

var articlesList = ""
var eventEdition = ""
export default class extends Controller {

  static targets = [ "formErrors", "messages", "articlesList", "eventEdition", "inviteItems", "days", "hours", "minutes", "seconds" ]

  connect() {
    if (document.body.getAttribute("data-action").includes("proceedings")) {
      articlesList = this.articlesListTarget
      eventEdition = this.eventEditionTarget
    }
    if (document.body.getAttribute("data-action").includes("show") || document.body.getAttribute("data-action").includes("preview")){
      this.setCountDown()
    }
    if (document.querySelector(".cronometer-products")) {
      this.setTicketCountDown()
    }
  }

  // Error form
  onPostError(event) {
    document.getElementById('invitation_form').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    this.formErrorsTarget.innerText = ""
    const [data, status, xhr] = event.detail
    for (var i = 0; i < JSON.parse(xhr.response).length; i++) {
      var error = JSON.parse(xhr.response)[i]
      var li = document.createElement("li")
      li.appendChild(document.createTextNode(error))
      this.formErrorsTarget.appendChild(li)
    }
  }

  // Success form
  onPostSuccess(event) {
    this.formErrorsTarget.innerText = ""
    let [data, status, xhr] = event.detail
  }

  openAccordion(event) {
    let item  = event.currentTarget.parentNode.parentNode.querySelector(".item")
    if (item.classList.contains("active")) {
      item.classList.remove("active")
    } else {
      item.classList.add("active")
    }
  }

  changeDateItem(event) {
    let dateItem = event.currentTarget
    dateItem.closest(".section-schedule").querySelectorAll(".date-item").forEach((item, i) => {
      item.classList.remove("active")
    })
    dateItem.classList.add("active")
    dateItem.closest(".section-schedule").querySelectorAll(".talks:not(.talks-premium)").forEach((item, i) => {
      item.classList.remove("active")
    })
    dateItem.closest(".section-schedule").querySelector(".talks[data-day='" + dateItem.getAttribute("data-day") + "']").classList.add("active")
  }

  changeProceedingDateItem(event) {
    let dateItem = event.currentTarget
    document.querySelectorAll(".date-item").forEach((item, i) => {
      item.classList.remove("active")
    })
    dateItem.classList.add("active")
    document.querySelectorAll(".edition-proceedings").forEach((item, i) => {
      item.classList.add("is-display-none")
    })
    document.querySelectorAll(".proceeding.title").forEach((item, i) => {
      item.classList.add("is-display-none")
    })
    document.querySelector(".proceeding.title[data-day='" + dateItem.getAttribute("data-day") + "']").classList.remove('is-display-none')
    document.querySelectorAll(".edition-proceedings[data-day='" + dateItem.getAttribute("data-day") + "']").forEach((item, i) => {
      item.classList.remove("is-display-none")
    })
  }

  changeProceeding(event) {
    let proceedingItem = event.currentTarget
    document.querySelectorAll(".proceeding-item").forEach((item, i) => {
      item.classList.remove("active")
    })
    proceedingItem.classList.add("active")
    document.querySelectorAll(".proceedings").forEach((item, i) => {
      item.classList.add("is-display-none")
    })
    document.querySelector(".proceedings[data-proceeding='" + proceedingItem.getAttribute("data-proceeding") + "']").classList.remove("is-display-none")
  }

  addInvite(event) {
    var divFlex = document.createElement("div")
    divFlex.classList.add("flex")
    var formGroup1 = document.createElement("div")
    formGroup1.classList.add("flex-item")
    formGroup1.classList.add("form-group")
    var input1 = document.createElement("input")
    input1.type = "text"
    input1.placeholder = "Nome"
    input1.name = 'invitation[invitations[][name]]'
    formGroup1.appendChild(input1)
    var formGroup2 = document.createElement("div")
    formGroup2.classList.add("flex-item")
    formGroup2.classList.add("form-group")
    var input2 = document.createElement("input")
    input2.type = "email"
    input2.placeholder = "Email"
    input2.name = 'invitation[invitations[][email]]'
    formGroup2.appendChild(input2)
    divFlex.appendChild(formGroup1)
    divFlex.appendChild(formGroup2)
    this.inviteItemsTarget.append(divFlex)
  }

  setCountDown() {
    const startAt = document.querySelector(".cronometer").getAttribute("data-start-at");
    let countDownDate;
    try {
      const date = new Date(startAt.endsWith(" UTC") ? startAt.replace(' UTC', 'Z') : startAt.replace(' ', 'T').replace(' -', '-'));
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", startAt);
      } else {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options = { timeZone: userTimeZone, year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        countDownDate = new Intl.DateTimeFormat('en-US', options).format(date);
      }
    } catch (error) {
      console.error("Error parsing date:", startAt);
    }
    countDownDate = new Date(countDownDate).getTime();
    //Update the count down every 1 second
    var daysHtml = this.daysTarget;
    var hoursHtml = this.hoursTarget;
    var minutesHtml = this.minutesTarget;
    var secondsHtml = this.secondsTarget;
    var x = setInterval(function () {
      //Get today's date and time
      var now = new Date().getTime();
      //Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (days < 10) {
        days = "0" + days;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (parseInt(days) == 0) {
        days = "0";
      }
      if (parseInt(hours) == 0) {
        hours = "0";
      }
      if (parseInt(minutes) == 0) {
        minutes = "0";
      }
      if (parseInt(seconds) == 0) {
        seconds = "0";
      }
      daysHtml.innerHTML = "<strong>" + days + "</strong>dia(s)";
      hoursHtml.innerHTML = "<strong>" + hours + "</strong>hora(s)";
      minutesHtml.innerHTML = "<strong>" + minutes + "</strong>minuto(s)";
      secondsHtml.innerHTML = "<strong>" + seconds + "</strong>segundo(s)";
      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  }

  setTicketCountDown(){
    var startAt = document.querySelector(".cronometer-products").getAttribute("data-start-at")
    var countDownDate = new Date(startAt.replace(' ', 'T').split(' ')[0]).getTime()

    // Update the count down every 1 second
    var daysHtml = this.daysTarget
    var hoursHtml = this.hoursTarget
    var minutesHtml = this.minutesTarget
    var secondsHtml = this.secondsTarget

    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime()

      // Find the distance between now and the count down date
      var distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)
      if (days < 10) {
        days = "0" + days
      }
      if (hours < 10) {
        hours = "0" + hours
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }
      if (seconds < 10) {
        seconds = "0" + seconds
      }
      if (parseInt(days) == 0){
        days = "0"
      }
      if (parseInt(hours) == 0){
        hours = "0"
      }
      if (parseInt(minutes) == 0){
        minutes = "0"
      }
      if (parseInt(seconds) == 0){
        seconds = "0"
      }

      daysHtml.innerHTML = days
      hoursHtml.innerHTML = hours
      minutesHtml.innerHTML = minutes
      secondsHtml.innerHTML = seconds

      if (distance < 0) {
        clearInterval(x)
      }
    }, 1000)
  }

  searchArticles(event) {
    let proceedingItem = document.querySelector(".proceeding-item.active")
    if (proceedingItem.getAttribute("data-proceeding") == "1") {
      fetch("/anais/resumos/buscar", {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          event_edition: eventEdition.textContent,
          text: event.currentTarget.value
        })
      }).then(res => {
        return res.text()
      }).then(data => {
        articlesList.innerHTML = data
      })
    } else if (proceedingItem.getAttribute("data-proceeding") == "3") {
      let dateItem = document.querySelector(".date-item.active")
      document.querySelectorAll(".edition-proceedings[data-day='" + dateItem.getAttribute("data-day") + "']").forEach((item, i) => {
        let text = item.querySelector("h4").innerText.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
        let searchValue = event.currentTarget.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
        if (text.includes(searchValue)) {
          item.classList.remove("is-display-none")
        } else {
          item.classList.add("is-display-none")
        }
      })
    }
  }

  facebookShare(event){
    let url = event.target.dataset.url
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`)
  }

  linkedinShare(event){
    let url = event.target.dataset.url
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`)
  }
}
