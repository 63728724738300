// JS Controller for global methods and functions
import { Controller } from "stimulus"
import Typed from 'typed.js'

export default class extends Controller {

  static targets = [ "typed" ]

  connect() {
    var options = {
      strings: ["Medicina", "Nutrição", "Saúde", "Educação", "Engenharia", "Direito", "Veterinária"],
      typeSpeed: 100,
      loop: true
    }
    var typed = new Typed(this.typedTarget, options);
  }

  showEvents(event) {
    if (event.currentTarget.textContent == "Mostrar todos") {
      event.currentTarget.textContent = "Mostrar menos"
    } else {
      event.currentTarget.textContent = "Mostrar todos"
    }
    document.querySelectorAll(".event.hidden").forEach((item, i) => {
      if (item.classList.contains("is-display-none")) {
        item.classList.remove("is-display-none")
      } else {
        item.classList.add("is-display-none")
      }
    })
  }
}