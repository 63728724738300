// JS Controller for global methods and functions
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "documentId", "email", "displayName", "documentNumberDiv", "fullAddressDiv", "nationalityDiv", "agreementSignModal" ]

  connect() {
    if (document.body.getAttribute("data-action").includes("subscribe_terms")) {
      this.showAgreement()
    }
    this.loadCKEditor()
  }

  showAgreement(){
    d4sign.configure({
      container: "signature-div",
      key: this.documentIdTarget.getAttribute("data-document-id"),
      protocol: "https",
      host: "secure.d4sign.com.br/embed/viewblob",
      signer: {
          email: this.emailTarget.getAttribute("data-email"),
          display_name: this.displayNameTarget.getAttribute("data-display-name"),
          disable_preview: "0"
      },
      width: '100%',
      height: '400',
      callback: function(event) {
      }
    })
  }

  loadCKEditor() {
    if (document.querySelector("#speaker_bio")) {
      let config = {
        customConfig: '/js/ckeditor_config.js',
        toolbar: [],
        height: 100,
        contentsCss: []
      }
      CKEDITOR.replace(document.querySelector('#speaker_bio'), config)
    }
  }

  openAgreementSignModal() {
    this.agreementSignModalTarget.style.display = 'block'
  }
}