// JS Controller for global methods and functions

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "formErrors" ]

  connect() {
  }

  // Error form
  onPostError(event) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.formErrorsTarget.innerText = ""
    const [data, status, xhr] = event.detail
    for (var i = 0; i < JSON.parse(xhr.response).length; i++) {
      var error = JSON.parse(xhr.response)[i]
      var li = document.createElement("li")
      li.innerHTML = error
      this.formErrorsTarget.appendChild(li)
    }
  }
}
